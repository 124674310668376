.evaluable-string-editor input,
.evaluable-string-editor textarea {
	border-bottom: 2px solid #0060df !important;
}

// javascript-editor
.swe-javascript-editor-codemirror .cm-wrap {
	height: 100%;
}
.swe-javascript-editor-codemirror .cm-scroller {
	overflow: auto;
}
.swe-javascript-editor-chat {
	display: flex;
	gap: 5px;
	width: 100%;
	flex-direction: row;
	margin: 10px 0 0;
}
.swe-javascript-editor-chat-input {
	flex: 1;
	border: 1px solid #bbb;
	outline: 0;
	padding: 5px;
	border-radius: 5px;
	height: 100px;
}
.swe-javascript-editor-chat-input:read-only {
	color: #333;
}
.swe-javascript-editor-chat-submit {
	background: #0060df;
	color: #fff;
	padding: 5px;
	border-radius: 5px;
	cursor: pointer;
}
.swe-javascript-editor-chat-submit:hover {
	background: #0040bf;
}
.swe-javascript-editor-chat-submit:disabled {
	background: #ccc;
	color: #333;
}
