.mcep-popup {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 9999999;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background: #5d5d5d;
}
.mcep-popup-placeholder {
	flex: 1;
}
.mcep-popup-bar {
	text-align: center;
	padding: 5px;
}
.mcep-popup-button {
	margin: 0 3px;
	border: 0;
	padding: 3px 10px;
	color: #fff;
	background: #757575;
	border-radius: 4px;
}
.mcep-popup-button:hover {
	background: #7d7d7d;
}
