@tailwind base;
@tailwind components;
@tailwind utilities;
@import './css/sequentialWorkflowDesigner.scss';
@import './css/sequentialWorkflowEditor.scss';
@import './css/miniCanvasEditor.css';
@import 'mini-canvas-editor/css/editor.css';
@import 'chartist/dist/index.scss';

html,
body,
#root {
	width: 100%;
	height: 100%;
}

// sequential-workflow-designer-react
.sqd-designer-react {
	width: 100%;
	height: 100%;
}
.sqd-smart-editor {
	position: relative;
}
.sqd-editor {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
}

// chartist
.ct-bar {
	stroke: #2a4dd0 !important;
}
