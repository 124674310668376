@import 'sequential-workflow-designer/sass/designer.scss';
@import 'sequential-workflow-designer/sass/designer-theme.scss';
@import '@nocode-js/sequential-workflow-designer-pro/sass/designer-pro-theme.scss';
@import 'sequential-workflow-editor/css/editor.css';

$selectionColor: #1c4fd8;

@include sqd-theme-ui-toolbox(
	'flat',
	$panelBoxShadow: 0 0 1px rgba(122, 122, 122, 0.6),
	$panelBorderRadius: 4px,
	$groupBackgroundColor: #f5f5f5,
	$filterBorderRadius: 4px,
	$groupBorderRadius: 4px
);
@include sqd-theme-ui-toolbox-item(
	'flat',
	$itemBorder: 1px solid #9da3ae,
	$itemBorderHovered: #868c95,
	$itemBoxShadow: 0 1.5px 1.5px rgba(0, 0, 0, 0.09),
	$itemBorderRadius: 4px
);
@include sqd-theme-ui-control-bar('flat', $panelBoxShadow: 0 0 1px rgba(122, 122, 122, 0.6));
@include sqd-theme-ui-editor(
	'flat',
	$panelBoxShadow: 0 -1px 1px rgba(122, 122, 122, 0.6),
	$toggleBoxShadow: 0 1px 1px rgba(122, 122, 122, 0.4)
);
@include sqd-theme-ui-context-menu('flat');

@include sqd-theme-workspace('flat', $backgroundColor: #fff);
@include sqd-theme-line-grid('flat', $strokeColor: #eceff1);
@include sqd-theme-join('flat', $joinStrokeColor: #4b5563);
@include sqd-theme-region('flat', $strokeWidth: 1, $strokeDasharray: 0, $strokeWidthSelected: 2, $strokeColorSelected: $selectionColor);
@include sqd-theme-placeholder(
	'flat',
	$rectFillColor: #dee9fc,
	$rectStrokeColor: #4b84ee,
	$rectStrokeWidth: 2,
	$rectStrokeDasharray: 0,
	$rectFillHovered: #4b84ee,
	$iconPathFillColor: #fff,
	$iconPathFillColorHovered: #fff
);
@include sqd-theme-validation-error-badge('flat');

@include sqd-theme-start-stop-root-component('flat', $circleFillColor: #4b5563, $iconFillColor: #fff);

@include sqd-theme-task-step-component(
	'flat',
	$rectFillColor: #fff,
	$rectStrokeColor: #9da3ae,
	$textColor: #000,
	$emptyIconColor: #c6c6c6,
	$rectStrokeColorSelected: $selectionColor,
	$inputStrokeColor: #4b5563,
	$inputFillColor: #fff,
	$outputFillColor: #fff,
	$outputStrokeWidth: 2,
	$outputStrokeColor: #4b5563
);

@include sqd-theme-switch-step-component(
	'flat',
	$labelPrimaryTextColor: #fff,
	$labelPrimaryFillColor: #4b5563,
	$labelSecondaryTextColor: #fff,
	$labelSecondaryFillColor: #9da3ae,
	$inputStrokeColor: #4b5563,
	$inputFillColor: #fff
);
@include sqd-theme-container-step-component('flat', $inputStrokeColor: #4b5563, $inputFillColor: #fff, $labelFillColor: #4b5563);

@include sqd-pro-theme-ui-path-bar('flat');
@include sqd-pro-theme-minimal-root-component('flat');
@include sqd-pro-theme-dot-grid('flat');
@include sqd-pro-theme-cross-grid('flat');
@include sqd-pro-theme-clickable-placeholder('flat', $rectFillColor: #4b5563, $rectFillColorHovered: #4b84ee, $iconFillColor: #fff);

@include sqd-pro-theme-icon-step-component('flat');
@include sqd-pro-theme-large-task-step-component(
	'flat',
	$rectStrokeColor: #9da3ae,
	$rectStrokeColorSelected: $selectionColor,
	$circleStrokeColor: #cccfd3,
	$inputStrokeColor: #4b5563,
	$inputFillColor: #fff,
	$outputFillColor: #fff,
	$outputStrokeWidth: 2,
	$outputStrokeColor: #4b5563
);
@include sqd-pro-theme-folder-step-component('flat');

@include sqd-pro-theme-counter-badge('flat');
@include sqd-pro-theme-loading-badge('flat');
